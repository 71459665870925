<template>
  <el-row :gutter="20">
    <el-col :span="8"
            v-if="level>=1">
      <el-select v-model="province"
                 clearable
                 class="width100"
                 placeholder="请选择省份"
                 :disabled="disabled"
                 @change="provinceChange">
        <el-option v-for="item in provinces"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="8"
            v-if="level>=2">
      <el-select v-model="city"
                 clearable
                 @change="cityChange"
                 :disabled="disabled"
                 class="width100"
                 placeholder="请选择城市">
        <el-option v-for="item in cities"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="8"
            v-if="level>=3">
      <el-select v-model="district"
                 @change="districtChange"
                 clearable
                 class="width100"
                 :disabled="disabled"
                 placeholder="请选择区">
        <el-option v-for="item in districts"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
        </el-option>
      </el-select>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    address: Object,
    level: {
      type: Number,
      default: 4
    },
    value: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      addressData: [],
      province: '',
      city: '',
      district: ''
    }
  },
  components: {
  },
  computed: {
    provinces () {
      return this.addressData
    },
    cities () {
      return this.formatData(this.province, this.provinces)
    },
    districts () {
      return this.formatData(this.city, this.cities)
    }
  },
  watch: {
    address (val) {
      this.init()
    },
    value () {
      this.init()
    }
  },
  methods: {
    init () {
      if (this.address) {
        this.province = this.address.province
        this.city = this.address.city
        this.district = this.address.district
      }
      if (this.value) {
        this.province = this.value.province
        this.city = this.value.city
        this.district = this.value.district
      }
    },
    updateData () {
      this.$emit('update:address', {
        province: this.province,
        city: this.city,
        district: this.district
      })
      this.$emit('change', {
        province: this.province,
        city: this.city,
        district: this.district
      })
      this.$emit('input', {
        province: this.province,
        city: this.city,
        district: this.district
      })
    },
    /**
     * 获取三级联动数据
     */
    async getAddress () {
      const res = await this.$store.dispatch('area/getAddress')
      this.addressData = res
    },
    /**
     * 返回子数组
     */
    formatData (key, data) {
      let address = []
      if (key && data.length > 0) {
        const res = data.find((item) => {
          return item.value === key
        })
        if (res) address = res.children
      }
      return address
    },
    checkInArray (key, data) {
      const res = data.find((item) => {
        return item.value === key
      })
      return res
    },
    provinceChange (e) {
      if (e) {
        if (!this.checkInArray(this.city, this.cities)) {
          this.city = ''
          this.district = ''
        }
      } else {
        this.city = ''
        this.district = ''
      }
      this.updateData()
    },
    cityChange (e) {
      if (e) {
        if (!this.checkInArray(this.district, this.districts)) {
          this.district = ''
        }
      } else {
        this.district = ''
      }
      this.updateData()
    },
    districtChange () {
      this.updateData()
    },
    reset () {
      this.province = ''
      this.city = ''
      this.district = ''
      this.updateData()
    }

  },
  mounted () {
    this.getAddress()
    this.init()
  }
}
</script>

<style lang="less" scoped>
</style>
