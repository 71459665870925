<template>
  <div class="container">
    <el-form ref="form"
             :rules="rules"
             label-position="top"
             :model="form">
      <el-form-item label="名称"
                    class="width300"
                    prop="name">
        <el-input v-model="form.name"
                  clearable
                  placeholder="请输入广告名称"></el-input>
      </el-form-item>
      <el-form-item label="显示区域">

        <template v-if="form.show_areas.length > 0">
          <el-form-item v-for="(item,index) in form.show_areas"
                        style="margin-bottom:20px"
                        :prop="'show_areas.' + index "
                        :rules="{
                           validator: showAreaRule, trigger: 'change'
                        }"
                        :key="index">
            <el-row type="flex">
              <Address style="flex:1"
                       v-model="form.show_areas[index]" />
              <el-button type="danger"
                         class="ml-15"
                         @click="delShowArea(index)">删除</el-button>
            </el-row>
          </el-form-item>

        </template>
        <div class="input-tip"
             v-else>已在全国显示</div>
        <el-button type="primary"
                   @click="addShowArea()">{{form.show_areas.length>0?'添加':'限制显示区域'}}</el-button>
      </el-form-item>
      <el-form-item label="不显示区域">

        <template v-if="form.hide_areas.length > 0">
          <el-form-item v-for="(item,index) in form.hide_areas"
                        style="margin-bottom:20px"
                        :prop="'hide_areas.' + index "
                        :rules="{
                           validator: hideAreaRule, trigger: 'change'
                        }"
                        :key="index">
            <el-row type="flex">
              <Address style="flex:1"
                       v-model="form.hide_areas[index]" />
              <el-button type="danger"
                         class="ml-15"
                         @click="delHideArea(index)">删除</el-button>
            </el-row>
          </el-form-item>

        </template>
        <div class="input-tip"
             v-else>不限制</div>
        <el-button type="primary"
                   @click="addHideArea()">{{form.hide_areas.length>0?'添加':'限制不显示区域'}}</el-button>
      </el-form-item>
      <el-form-item label="图片"
                    prop="img_id">
        <ImgUpload v-model="form.img_id"
                   :pixel="imgWH"
                   :fileList="imgList" />
      </el-form-item>
      <el-form-item label="链接类型"
                    required>
        <el-radio-group v-model="form.url_type"
                        @change="radioChange">
          <el-radio label="NOTHING">无</el-radio>
          <el-radio label="H5">h5</el-radio>
          <el-radio label="WYH">微邮惠路径</el-radio>
          <el-radio label="MINI">外部小程序</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="AppID"
                    prop="app_id"
                    class="width300"
                    v-if="form.url_type === 'MINI'">
        <el-input v-model="form.app_id"
                  clearable
                  placeholder="请输入小程序appid"></el-input>
      </el-form-item>
      <el-form-item label="小程序内部路径"
                    v-if="form.url_type === 'MINI'"
                    ref="url"
                    class="width300"
                    prop="url"
                    :rules="[
                      {  validator: this.validatorAppid, trigger: 'blur' }
                    ]">
        <el-input v-model="form.url"
                  clearable
                  placeholder="请输入小程序内部路径"></el-input>
      </el-form-item>
      <el-form-item label="H5链接"
                    v-if="form.url_type === 'H5'"
                    ref="url"
                    class="width300"
                    prop="url">
        <el-input v-model="form.url"
                  clearable
                  placeholder="请输入H5链接"></el-input>
      </el-form-item>
      <el-form-item label="内部链接"
                    v-if="form.url_type === 'WYH'"
                    ref="url"
                    class="width300"
                    prop="url">
        <el-input v-model="form.url"
                  clearable
                  placeholder="请输入微邮惠路径"></el-input>
      </el-form-item>
      <el-form-item label="排序"
                    class="width300"
                    prop="sort">
        <el-input v-model.number="form.sort"
                  clearable
                  placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item label="日期规则"
                    prop="started_at">
        <WxDate @change="dateChange"
                ref="wxDate"
                :dateRule="dateRule" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">{{form.id?'修改':'立即创建'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Address from '@/components/Address'
import WxDate from '@/components/WxDate'
import ImgUpload from '@/components/ImgUpload'
import { getImgId } from '@/utils'
import { setAdverts, fetchAdvertDetail } from '@/api/adverts'

export default {
  data () {
    return {
      dateRule: {},
      address: {
        province: '',
        city: '',
        district: ''
      },
      noAddress: {
        province: '',
        city: '',
        district: ''
      },
      form: {
        name: '',
        type: this.$route.meta.type,
        url_type: 'NOTHING',
        app_id: '',
        url: '',
        status: 'NORMAL',
        sort: 500,
        show_areas: [],
        hide_areas: [],
        img_id: '',
        started_at: '',
        ended_at: '',
        time_rule: 'ANY',
        time_rule_day: [],
        time_rule_time: [],
        time_rule_week: [1, 2, 3, 4, 5, 6, 7]
      },
      rules: {
        started_at: [
          { required: true, message: '选择活动日期', trigger: 'change' }
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
        app_id: [
          { required: true, message: '请输入appid', trigger: 'blur' }
        ],
        url: [
          { required: true, validator: this.validatorAppid, trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        img_id: [
          { required: true, message: '请上传广告图片', trigger: 'change' }
        ]
      },
      imgList: []
    }
  },
  computed: {
    imgWH () {
      let pixel = ''
      switch (this.$route.meta.type) {
        case 'INDEX_BANNER':
          pixel = '用于页面配图，建议使用640*400像素'
          break
        case 'INDEX_ACTIVITY':
          pixel = '用于页面配图，建议使用640*200像素'
          break
        case 'MERCHANTS_BANNER':
          pixel = '用于页面配图，建议使用640*100像素'
          break
        case 'USER_BANNER':
          pixel = '用于页面配图，建议使用640*300像素'
          break
        default:
          break
      }
      return pixel
    }
  },
  components: {
    Address,
    ImgUpload,
    WxDate
  },
  methods: {
    dateChange (e) {
      this.form = Object.assign(this.form, e)
    },
    showAreaRule (rule, value, callback) {
      let index = 0
      if (value.province) {
        for (const k of this.form.show_areas) {
          if (k.province === value.province && k.city === value.city && k.district === value.district) {
            index++
          }
        }
        if (index >= 2) {
          callback(new Error('限制区域不能重复'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请选择省份'))
      }
    },
    hideAreaRule (rule, value, callback) {
      let index = 0
      if (value.province) {
        for (const k of this.form.hide_areas) {
          if (k.province === value.province && k.city === value.city && k.district === value.district) {
            index++
          }
        }
        if (index >= 2) {
          callback(new Error('限制区域不能重复'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请选择省份'))
      }
    },
    validatorAppid (rule, value, callback) {
      if (this.form.url_type === 'H5') {
        if (this.form.url) {
          callback()
        } else {
          callback(new Error('请输入h5链接'))
        }
      } else if (this.form.url_type === 'MINI') {
        callback()
      } else {
        if (this.form.url) {
          callback()
        } else {
          callback(new Error('请输入小程序内部路径'))
        }
      }
    },
    radioChange () {
      this.form.url = ''
      this.form.app_id = ''
      this.$refs.url && this.$refs.url.clearValidate()
    },
    addShowArea () {
      this.form.show_areas.push({
        province: '',
        city: '',
        district: ''
      })
    },
    delShowArea (index) {
      this.form.show_areas.splice(index, 1)
    },
    addHideArea () {
      this.form.hide_areas.push({
        province: '',
        city: '',
        district: ''
      })
    },
    delHideArea (index) {
      this.form.hide_areas.splice(index, 1)
    },
    showModel (formData) {
      this.dialogVisible = true
      if (formData) {
        this.address = {
          province: formData.province,
          city: formData.city,
          district: formData.district,
          net: formData.net
        }
        this.$nextTick(() => {
          const form = Object.assign({}, formData)
          form.advert_id = formData.id
          form.img_id = getImgId(formData.img_data)
          this.form = form
          this.imgList = [formData.img_data]
          this.$refs.form.resetFields()
        })
      } else {
        this.$nextTick(() => {
          this.resetForm()
        })
      }
    },
    submitForm (formName) {
      const { form } = this
      this.$nextTick(() => {
        const wxDateStatus = this.$refs.wxDate.validate()
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            if (!wxDateStatus) {
              return
            }
            const res = await setAdverts(form)
            if (res.meta.code === 0) {
              this.$notify({
                title: '成功',
                message: `${this.form.id ? '修改' : '创建'}成功`,
                type: 'success'
              })
              switch (this.$route.meta.type) {
                case 'INDEX_BANNER':
                  this.$router.push('homeCarousel')
                  break
                case 'INDEX_ACTIVITY':
                  this.$router.push('homeActivity')
                  break
                case 'MERCHANTS_BANNER':
                  this.$router.push('merchantBanner')
                  break
                case 'USER_BANNER':
                  this.$router.push('userBanner')
                  break
                default:
                  break
              }
            } else {
              this.$alert(res.meta.msg, {
                type: 'error',
                content: res.meta.msg
              })
            }
          }
        })
      })
    },
    async fetchAdvertDetail () {
      const res = await fetchAdvertDetail({
        id: this.$route.query.id
      })
      if (res.meta.code === 0) {
        const data = res.data.data
        data.img_id = getImgId(data.img_data)
        data.img_data && this.$set(this.imgList, 0, data.img_data)

        this.form = data
        this.dateRule = {
          time_rule: data.time_rule,
          started_at: data.started_at,
          ended_at: data.ended_at,
          time_rule_week: data.time_rule_week || [],
          time_rule_time: data.time_rule_time || [],
          time_rule_day: data.time_rule_day || []
        }
      }
    }
  },
  mounted () {
    this.fetchAdvertDetail()
  }
}
</script>

<style lang="scss" scoped>
</style>
