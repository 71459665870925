import request from '@/utils/request'

export const fetchAdverts = async (params) => {
  return request({
    url: '/api/mall/admin/adver/get/lists',
    method: 'get',
    params
  })
}
export function delAdverts (params) {
  return request({
    url: '/api/mall/admin/adver/get/delete',
    method: 'get',
    params
  })
}
export function setAdverts (data) {
  return request({
    url: '/api/mall/admin/adver/post/edit',
    method: 'post',
    data
  })
}
export const fetchAdvertDetail = async (params) => {
  return request({
    url: '/api/mall/admin/adver/get/details',
    method: 'get',
    params
  })
}
